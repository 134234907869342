import { combineReducers, configureStore } from '@reduxjs/toolkit';
import {
  FLUSH,
  PAUSE,
  PERSIST, persistReducer, persistStore,
  PURGE,
  REGISTER, REHYDRATE,
} from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import { questionSlice } from './question/slice';
import { quizzsSlice } from './quizzs/slice';
import { usersSlice } from './users/slice';

const persistConfig = {
  key: 'root',
  version: 1,
  storage,
};

const rootReducer = combineReducers({
  [quizzsSlice.name]: quizzsSlice.reducer,
  [usersSlice.name]: usersSlice.reducer,
  [questionSlice.name]: questionSlice.reducer,
});

const persistedReducer = persistReducer(persistConfig, rootReducer);

// Set all reducers in combineReducer
const createStore = () => {
  const store = configureStore({
    reducer: persistedReducer,
    middleware: (getDefaultMiddleware) => getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
      },
    }),
  });
  const persistor = persistStore(store);
  return { store, persistor };
};

const { store, persistor } = createStore();
export { store };
export { persistor };
