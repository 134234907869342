import styled from 'styled-components';
import { colors } from '../../utils/vars';
import { breakpoint } from '../../utils/mixins';

const StyledCircularProgress = styled.div`
  display: flex;
  place-items: center;
  width: 150px;
  height: 150px;
  margin: 0 auto;
  border: 5px solid ${colors.white};
  background-color: ${colors.white};
  border-radius: 50%;
  box-shadow: ${colors.white} 0 0 10px 2px; 
  ${breakpoint.xxs`
       height: 120px;
       width: 120px;
    `}
`;

export default StyledCircularProgress;
