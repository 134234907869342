/* eslint-disable prefer-destructuring */
/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit';

export const initialState = {
  firstName: null,
  lastName: null,
  group: null,
  userId: null,
};

export const usersSlice = createSlice({
  name: 'users',
  initialState,
  reducers: {
    addUser: (state, action) => {
      state.firstName = action.payload.firstName;
      state.lastName = action.payload.lastName;
      state.group = action.payload.group;
      state.userId = action.payload.userId;
    },
    resetUsers: (state) => {
      state.firstName = null;
      state.lastName = null;
      state.group = null;
      state.userId = null;
    },
  },
});

export const {
  addUser, resetUsers,
} = usersSlice.actions;
