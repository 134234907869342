import styled from 'styled-components';
import { colors } from '../../utils/vars';
import { breakpoint } from '../../utils/mixins';

const StyleLogin = styled.div`
  display: flex;
  flex-direction: column;
  gap: 50px;
  width: 100%;
   
   ${breakpoint.xxs`
         gap: 20px;
   `}
   
  h1 {
    color: ${colors.thunder};
    font-family: 'Canaro', sans-serif;
    font-size: 3rem;
    font-weight: 800;
    text-align: center;
    text-transform: uppercase;
  }
  
  .logo-wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    max-width: 250px;
    min-height: 200px;
    max-height: 250px;
    padding: 10px;
    border-radius: 20px;
    margin: 0 auto;
    // box-shadow: ${colors.white} 0 0 7px 5px;
    // backdrop-filter: blur(10px);
    figure {
      height: 100%;
      width: 100%;
      max-width: 250px;
      max-height: 250px;
      img {
        height: 100%;
        width: 100%;
        object-fit: cover;
      }
    }
  }
  
  .login-field {
    display: block;
    max-width: 360px;
    width: 100%;
    margin: 0 auto;
    
    .login-label {
      color: ${(props) => props.theme.labelColor};
      font-family: 'Canaro', sans-serif;
      font-size: 1.4rem;
      font-weight: 700;
      text-transform: uppercase;
    }
    
    input {
      display: block;
      width: 100%;
      height: 50px;
      padding: 0 20px;
      border: 0;
      border-radius: 3px;
      box-shadow: 2px 3px 6px 3px rgba(35, 31, 32, .1);
      background-color: ${colors.white};
      color: ${colors.manatee};
      font-size: 1.4rem;
      box-sizing: border-box;
      
      :focus,
      &:hover {
        outline: 2px solid ${(props) => props.theme.primary}; 
      }
    }
    
    .login-select {
      width: 100%;
      box-shadow: 2px 3px 6px 3px rgba(35, 31, 32, .1);

      &__control {
        width: 100%;
        border: 0 !important;
        box-shadow: none !important;
        background-color: ${colors.white};
        transition: none;
        cursor: pointer;
        border-radius: 3px;
        
        &--is-focused {
          outline: ${(props) => props.theme.primary} !important; 
        }

        &:hover {
          outline: 2px solid ${(props) => props.theme.primary};
        }
        
        &--menu-is-open {
          .login-select__dropdown-indicator {
            transform: rotateX(-180deg);
          }
        }
      }
      
      &__value-container {
        height: 50px;
        padding: 0 20px;
      }
      
      &__single-value {
        color: ${colors.manatee};
        font-size: 1.4rem;
        font-weight: bold;
      }
      
      &__indicator-separator {
        display: none;
      }
      
      &__dropdown-indicator {
        padding: 0 20px;
        transition: transform .2s;
        color: ${colors.manatee};
        will-change: transform;
      }
      
      &__menu {
        margin: 5px 0;
        border-radius: 3px;
        box-shadow: 2px 3px 6px 3px rgba(35, 31, 32, .1);
      }
      
      &__menu-list {
        padding: 0;
      }
      
      &__option {
        cursor: pointer;
        padding: 10px 20px;
        font-weight: 700;
        
        &--is-focused {
          background-color: ${colors.athens};
        }
        
        &--is-selected {
          background-color: ${(props) => props.theme.primary};
          color: ${colors.white};
        }
      }
    }
  }

  .message-error {
    margin-top: 8px;
    color: ${colors.blush};
    font-size: 1.1rem;
  }
  
  form {
    display: flex;
    flex-direction: column;
    gap: 80px;
    ${breakpoint.xxs`
        gap: 30px;
        padding : 20px;
     `}
    .inputs-wrapper {
      display: flex;
      flex-direction: column;
      gap: 30px;
    }
  }
`;

export default StyleLogin;
