import styled from 'styled-components';
import { colors } from '../../utils/vars';
import { breakpoint } from '../../utils/mixins';

const StyleQuestion = styled.form`
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  width: 100%;
  max-width: 600px;
  padding-bottom: 20px;
  gap: 50px;
  overflow-y: auto;
  margin: 100px auto 0;

  h1 {
    color: ${(props) => props.theme.primary};
    font-family: 'Merriweather', sans-serif;
    font-size: 3rem;
    font-weight: 900;
    font-style: italic;
    text-align: center;
    line-height: 1.2;
    ${breakpoint.s`
         font-size: 2rem;
    `}
  }

  .content-form {
    display: flex;
    flex-direction: column;
    width: 100%;
    gap: 20px;

    .question-img {
      display: block;
      margin: 30px auto;
      box-shadow: 2px 3px 6px 3px rgba(35, 31, 32, .1);

      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }

    .question-video {
      display: block;
      width: 100%;
      aspect-ratio: 16/9;
      box-shadow: 2px 3px 6px 3px rgba(35, 31, 32, .1);
    }
  }

  .wait-for-next-wrapper {
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    font-size: 2rem;
    text-align: center;
    gap: 20px;
    ${breakpoint.xxs`
      font-size: 1.6rem;
     `}
    span {
      font-weight: 700;
      color: ${(props) => props.theme.primary};
    }
    
    svg {
      width: 130px;
      height: 130px;

      ${breakpoint.xxs`
        width: 100px;
        height: 100px;
     `}
      path {
        //fill: ${(props) => props.theme.secondary};
      }
    }
  }
`;

export default StyleQuestion;
