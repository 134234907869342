import { useState, useEffect } from 'react';

const ProgressProvider = ({ valueStart, valueEnd, children }) => {
  const [value, setValue] = useState(valueStart);

  useEffect(() => {
    if (valueEnd && value < (valueEnd - 1)) {
      setTimeout(() => {
        setValue(value + 1);
      }, 20);
    }
    if (valueEnd && (valueEnd - value) >= 0 && (valueEnd - value) <= 1) {
      setTimeout(() => {
        setValue(value + (valueEnd - value));
      }, 20);
    }
  }, [valueEnd, value]);

  return children(value);
};
export default ProgressProvider;
