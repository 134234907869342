import React from 'react';
import StyleLoad from './styled';

const Load = () => (
  <StyleLoad>
    <div className="logo">
      <h1>Sevaplay</h1>
      <p>by sevanova</p>
    </div>
  </StyleLoad>
);

export default Load;
