import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { currentTimer } from '../../Store/question/slice';
import Counter from '../Counter';
import StyleHeader from './styled';

const Header = () => {
  const { status, step } = useSelector((state) => state.quizzs);
  const { timeLeft } = useSelector((state) => state.question);
  const { firstName, lastName } = useSelector((state) => state.users);
  const dispatch = useDispatch();

  useEffect(() => {
    const interval = setInterval(() => {
      dispatch(currentTimer());
    }, 200);
    return () => clearInterval(interval);
  }, [step]);
  if (status !== 'loading' && status !== 'start') {
    return (
      <StyleHeader>
        <p>
          Bonjour
          <span>
            {firstName}
            &nbsp;
            {lastName}
          </span>
        </p>
        {
           status !== 'finish' && (
           <Counter
             time={timeLeft}
           />
           )
        }

      </StyleHeader>
    );
  } return null;
};

export default Header;
