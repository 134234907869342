import { createGlobalStyle } from 'styled-components';
import { colors } from './vars';

import bg from '../images/bg.png';

// Fonts
import canaroExtraBoldttf from '../fonts/Canaro-ExtraBold.ttf';
import canaroExtraBoldwoff from '../fonts/Canaro-ExtraBold.woff';
import canaroExtraBoldwoff2 from '../fonts/Canaro-ExtraBold.woff2';
import canaroBoldttf from '../fonts/Canaro-Bold.ttf';
import canaroBoldwoff from '../fonts/Canaro-Bold.woff';
import canaroBoldwoff2 from '../fonts/Canaro-Bold.woff2';
import canaroSemiBoldttf from '../fonts/Canaro-SemiBold.ttf';
import canaroSemiBoldwoff from '../fonts/Canaro-SemiBold.woff';
import canaroSemiBoldwoff2 from '../fonts/Canaro-SemiBold.woff2';
import canaroBookttf from '../fonts/Canaro-Book.ttf';
import canaroBookwoff from '../fonts/Canaro-Book.woff';
import canaroBookwoff2 from '../fonts/Canaro-Book.woff2';

const GlobalStyle = createGlobalStyle`
  body {
    background-color: ${colors.athens};
    background-repeat: no-repeat;
    background-position: center bottom -150px;
    color: ${colors.manatee};
    font-family: 'Montserrat', sans-serif;
    font-size: 1.4rem;
    font-weight: 400;
    line-height: 1.2;
    width: 100%;
    height: 100%;
  }
  

  @font-face {
    font-family: 'Canaro';
    src: url(${canaroExtraBoldttf}) format('truetype'), url(${canaroExtraBoldwoff}) format('woff'), url(${canaroExtraBoldwoff2}) format('woff2');
    font-weight: 900;
    font-style: normal;
  }

  @font-face {
    font-family: 'Canaro';
    src: url(${canaroBoldttf}) format('truetype'), url(${canaroBoldwoff}) format('woff'), url(${canaroBoldwoff2}) format('woff2');
    font-weight: 700;
    font-style: normal;
  }

  @font-face {
    font-family: 'Canaro';
    src: url(${canaroSemiBoldttf}) format('truetype'), url(${canaroSemiBoldwoff}) format('woff'), url(${canaroSemiBoldwoff2}) format('woff2');
    font-weight: 600;
    font-style: normal;
  }

  @font-face {
    font-family: 'Canaro';
    src: url(${canaroBookttf}) format('truetype'), url(${canaroBookwoff}) format('woff'), url(${canaroBookwoff2}) format('woff2');
    font-weight: 400;
    font-style: normal;
  }
`;

export default GlobalStyle;
