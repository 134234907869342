import styled from 'styled-components';
import { colors } from '../../utils/vars';

const StyleCheck = styled.div`
  margin-top: 22px;

  &:hover {
    color: ${colors.rhino};
  }

  input {
    position: absolute;
    z-index: -999;
    opacity: 0;
    
    &:focus {
      & ~ label {
        outline: 2px solid rgba(35, 31, 32, .8); 
      }
    }

    &:checked {
      & ~ label {
        box-shadow: none;
        background-color: ${(props) => props.theme.primary};
        color: ${colors.white};
        
        &::before {
          background-color: ${colors.white};
        }
        
        &:hover {
          outline: 2px solid rgba(35, 31, 32, .8); 
        }

        svg {
          opacity: 1;
        }
      }
    }
  }

  label {
    display: block;
    position: relative;
    padding: 19px 20px 18px 55px;
    transition: box-shadow .3s, background-color .3s, color .3s;
    box-shadow: 2px 3px 6px 3px rgba(35, 31, 32, .1);
    background-color: ${colors.white};
    color: ${colors.thunder};
    cursor: pointer;

    &::before {
      content: '';
      position: absolute;
      top: 50%;
      left: 16px;
      width: 16px;
      height: 16px;
      transform: translateY(-50%);
      border-radius: ${(p) => (p.type === 'radio' ? '50%' : '2px')};
      background-color: rgba(146, 149, 165, .4);
    }
    
    &:hover {
      outline: 2px solid rgba(35, 31, 32, .8); 
    }

    svg {
      display: block;
      position: absolute;
      z-index: 1;
      top: 50%;
      left: 19px;
      width: 10px;
      height: 10px;
      opacity: 0;
      transition: opacity .3s;
      transform: translateY(-50%);
      fill: ${(props) => props.theme.primary};
    }
    
    &.disabled {
      opacity: .7;
      pointer-events: none;
    }
  }

`;

export default StyleCheck;
