/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit';

export const initialState = {
  question: null,
  responses: [],
  time: 0,
  timerStarting: false,
  timestampStart: null,
  timestampStop: null,
  timeLeft: 0,
  responsesLocked: false,
};

export const questionSlice = createSlice({
  name: 'question',
  initialState,
  reducers: {
    startTimer: (state, action) => {
      const actualDate = new Date().getTime();
      state.time = action.payload.time;
      state.question = action.payload.question;
      state.timerStarting = true;
      state.timestampStart = actualDate;
      state.timestampStop = actualDate + state.time * 1000;
      state.timeLeft = action.payload.time;
      state.responsesLocked = false;
    },
    stopTimer: (state) => {
      state.timerStarting = false;
      state.timestampStart = null;
      state.timestampStop = null;
      state.timeLeft = 0;
    },
    currentTimer: (state) => {
      if (state.timeLeft < 99) {
        const currentDate = new Date().getTime();
        const diffTime = state.timestampStop - currentDate;
        if (diffTime < 0) {
          state.timeLeft = 0;
          state.responsesLocked = true;
        } else {
          state.timeLeft = Math.round(diffTime / 1000);
        }
      }
    },
    resetQuestion: (state) => {
      state.question = null;
      state.responses = [];
      state.time = 0;
      state.timerStarting = false;
      state.timestampStart = null;
      state.timestampStop = null;
      state.timeLeft = 0;
      state.responsesLocked = false;
    },
  },
});

export const {
  startTimer, stopTimer, currentTimer, resetQuestion,
} = questionSlice.actions;
