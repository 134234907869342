import styled from 'styled-components';
import { colors } from '../../utils/vars';

const StyleButton = styled.div`
  text-align: center;
  
  button {
    padding: 15px 50px;
    border-radius: 5px;
    transition: background-color .3s;
    background-color: ${(props) => props.theme.primary};
    color: ${colors.white};
    font-family: 'Canaro', sans-serif;
    font-size: 1.1rem;
    font-weight: 700;
    text-transform: uppercase;
    
    &.disabled {
      opacity: .7;
      pointer-events: none;
    }
    
    :focus {
      outline: 2px solid rgba(35, 31, 32, .8); 
    }

    :hover {
      background-color: ${(props) => props.theme.secondary};
    }
  }
`;

export default StyleButton;
