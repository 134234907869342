import React, { useEffect } from 'react';

function ReloadOnBrowserOpen() {
  useEffect(() => {
    const handleVisibilityChange = () => {
      if (document.visibilityState === 'visible') {
        // Recharge la page lorsque le navigateur est réouvert
        window.location.reload();
      }
    };
    document.addEventListener('visibilitychange', handleVisibilityChange);

    return () => {
      // Supprime l'écouteur d'événement lorsque le composant est démonté
      document.removeEventListener('visibilitychange', handleVisibilityChange);
    };
  }, []);

  return <div />; // Remplacez par votre contenu JSX
}

export default ReloadOnBrowserOpen;
