import styled from 'styled-components';
import { colors } from '../../utils/vars';
import { breakpoint } from '../../utils/mixins';

const StyleGum = styled.div`
  width: ${(props) => (props?.width ? props.width : '70px')};
  height: ${(props) => (props?.height ? props.height : '70px')};
  position: absolute;
  top: ${(props) => (props?.top ? props.top : 'unset')};;
  right: ${(props) => (props?.right ? props.right : 'unset')};
  left: ${(props) => (props?.left ? props.left : 'unset')};
  bottom: ${(props) => (props?.bottom ? props.bottom : 'unset')};
  background: ${colors.white};
  opacity: .3;
  border-radius: ${(props) => (props?.borderRadius ? props.borderRadius : '50px 0 80px 20px')};
  box-shadow: ${(props) => ((props.shadow) ? `${colors.black} ${props.shadow}` : `${colors.black} -4px -3px 3px`)};
  animation: bounce 5s infinite ease-in-out;

  ${breakpoint.xxs`
       width: 50px;
       height: 50px;
       animation: bounce 5s infinite ease-in-out;

  `}

  @keyframes bounce {
    0% { transform: translateY(-5px)  }
    50% { transform: translateY(10px) }
    100% { transform: translateY(-5px) }
  } ;
`;

export default StyleGum;
