/* eslint-disable react/jsx-props-no-spreading */
import PropTypes from 'prop-types';
import React from 'react';
import StyleInput from './styled';

const Input = ({
  register, disabled, defaultValue,
}) => (
  <StyleInput
    type="text"
    {...register('responses')}
    defaultValue={defaultValue}
    className={disabled && 'disabled'}
    disabled={disabled}
  />
);

Input.propTypes = {
  register: PropTypes.func.isRequired,
  disabled: PropTypes.bool.isRequired,
  defaultValue: PropTypes.string,
};

Input.defaultProps = {
  defaultValue: null,
};

export default Input;
