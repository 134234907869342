import styled from 'styled-components';
import { colors } from '../../utils/vars';
import { breakpoint } from '../../utils/mixins';

const StyleWait = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 20px;
  padding: 0 5px;
  span {
  color: ${(props) => props.theme.primary};
  font-family: 'Merriweather', sans-serif;
  font-size: 4.5rem;
  font-style: italic;
  text-align: center;
  ${breakpoint.s`
         font-size: 2rem;
  `}
  }
`;

export default StyleWait;
