/* eslint-disable react/prop-types */
import React from 'react';
import ReactWordcloud from 'react-wordcloud';

const options = {
  fontSizes: [12, 120],
};

const WordCloud = ({ words }) => (
  <ReactWordcloud
    size={[600, 400]}
    words={words}
    options={options}
  />
);

export default WordCloud;
