import PropTypes from 'prop-types';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import io from 'socket.io-client';
import { resetQuestion } from '../Store/question/slice';
import { finish, reset } from '../Store/quizzs/slice';
import { resetUsers } from '../Store/users/slice';

const SocketContext = React.createContext();
const { REACT_APP_BACK_URL } = process.env;
const ENDPOINT = `${REACT_APP_BACK_URL}`;

const socket = io(ENDPOINT, {
  transports: ['websocket'],
  upgrade: true,
  reconnection: true,
  reconnect_attempt: 1000,
});

const SocketProvider = ({ children }) => {
  const dispatch = useDispatch();
  const { roomId } = useSelector((state) => state.quizzs);

  useEffect(() => {
    if (roomId) {
      socket.emit('joinQuizzSession', roomId);
      socket.on('restart', () => {
        dispatch(reset());
        dispatch(resetQuestion());
        dispatch(resetUsers());
      });
      socket.on('finish', (data) => {
        console.log('finishhh');
        dispatch(finish(data));
      });
      socket.on('connect', () => {
        // Gérer le délai de connexion
        console.log('Connect');
        socket.emit('infosGroups', {
          room: roomId,
        });
      });
      socket.on('connect_timeout', () => {
        // Gérer le délai de connexion
        console.log('Délai de connexion Socket.IO dépassé');
      });

      socket.on('connect_error', (error) => {
        // Gérer l'erreur de connexion
        console.log('Erreur de connexion Socket.IO :', error);
      });
    }
  }, [socket, roomId]);

  return (
    <SocketContext.Provider value={socket}>
      {children}
    </SocketContext.Provider>
  );
};

SocketProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export { SocketContext, SocketProvider };
