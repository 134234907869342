import React from 'react';
import PropTypes from 'prop-types';
import StyledBackground from './styled';

const Background = ({
  url, repeat,
}) => (<StyledBackground url={url} repeat={repeat} />);

Background.defaultProps = {
  repeat: false,
};

Background.propTypes = {
  url: PropTypes.string.isRequired,
  repeat: PropTypes.bool,
};

export default Background;
