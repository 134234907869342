import styled from 'styled-components';
import { colors, headerHeight } from '../../utils/vars';

const StyleHeader = styled.header`
  display: flex;
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  justify-content: space-between;
  align-items: center;
  padding: 0 20px;
  height: ${headerHeight};
  gap: 10px;
  p {
    color: ${colors.thunder};
    font-family: 'Canaro', sans-serif;
    font-size: 1.4rem;
    font-weight: 700;
    text-transform: uppercase;
    
    span {
      display: block;
      color: ${colors.manatee};
      font-family: 'Merriweather', sans-serif;
      font-size: 1.9rem;
      font-style: italic;
      text-transform: capitalize;
    }
  }
`;

export default StyleHeader;
