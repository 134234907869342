/* eslint-disable prefer-destructuring */
/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit';

export const initialState = {
  question: null,
  questions: [],
  responseSended: false,
  currentResponses: null,
  status: localStorage.getItem('isFinish') ? 'finish' : 'loading',
  size: 0,
  step: null,
  roomId: null,
  userId: null,
  classmentByGroup: [],
  classmentByUser: [],
};

export const quizzsSlice = createSlice({
  name: 'quizzs',
  initialState,
  reducers: {
    allowResponseSended: (state, action) => {
      state.responseSended = action.payload;
    },
    addQuestions: (state, action) => {
      console.log('add questions .. ');
      state.questions = action.payload;
    },
    addRoomId: (state, action) => {
      console.log('add room .. ');
      state.roomId = action.payload;
    },
    next: (state, action) => {
      state.responseSended = false;
      switch (state.status) {
        case 'loading':
          state.status = 'start';
          state.step = null;
          break;
        case 'start':
          state.status = 'wait';
          state.step = null;
          state.size = state.questions.scenario.length;
          state.question = state.questions.scenario[0];
          break;
        case 'wait':
          state.status = 'play';
          state.step = parseInt(action.payload, 10);
          state.question = state.questions.scenario[state.step];
          break;
        case 'finish':
          state.status = 'finish';
          state.step = null;
          state.question = state.questions.scenario[state.step];
          break;
        default:
          console.log('Defaul play');
          state.step = parseInt(action.payload, 10);
          state.status = 'play';
          state.question = state.questions.scenario[state.step];
          break;
      }
    },
    finish: (state, action) => {
      state.status = 'finish';
      state.step = null;
      if (state.step) {
        state.question = state?.questions?.scenario[state.step];
      }
      state.classmentByGroup = action?.payload?.classmentByGroup;
      state.classmentByUser = action?.payload?.classmentByUser;
      localStorage.setItem('isFinish', true);
    },
    reset: (state) => {
      localStorage.clear();
      state.question = null;
      state.responseSended = false;
      state.currentResponses = null;
      state.status = 'loading';
      state.size = 0;
      state.step = 0;
      state.userId = null;
      state.classmentByGroup = [];
      state.classmentByUser = [];
      localStorage.clear();
      window.location.reload();
    },
  },
});

export const {
  addQuestions, addRoomId, next, reset, allowResponseSended, finish,
} = quizzsSlice.actions;
