import styled from 'styled-components';
import { colors } from '../../utils/vars';

const StyledBackground = styled.div`
  height: 100%;
  width: 100%;
  position: fixed;
  z-index: -1;
  background-image: ${(props) => `url(${props.url})`};
  background-size: ${(props) => (props?.repeat ? 'contain' : 'unset')};
  background-repeat: ${(props) => (props?.repeat ? 'repeat' : 'unset')};
`;

export default StyledBackground;
