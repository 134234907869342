import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import StyledCounter from './styled';

const Counter = ({ time }) => {
  const [danger, setDanger] = useState(false);
  useEffect(() => {
    if (time <= 10 && !danger) {
      setDanger(true);
    }
  }, [time]);
  return (
    <StyledCounter danger={!!(danger)} className={(danger && time > 0 && time <= 10) ? 'animated' : ''}>
      { time < 0 ? 0 : (time < 99 ? time : '-') }
    </StyledCounter>
  );
};

Counter.propTypes = {
  time: PropTypes.number.isRequired,
};

export default Counter;
