import React from 'react';
import StyleGum from './styled';

const Gum = (props) => (
  <StyleGum {...props} />
);

Gum.defaultProps = {

};

export default Gum;
