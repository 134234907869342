import styled from 'styled-components';
import { colors } from '../../utils/vars';

const StyleInput = styled.input`
  display: block;
  width: 100%;
  height: 65px;
  margin-top: 30px;
  border: 0;
  border-radius: 3px;
  background-color: ${colors.white};
  box-shadow: 2px 3px 6px 3px rgba(35, 31, 32, .1);
  font-family: 'Canaro', sans-serif;
  font-size: 2rem;
  font-weight: 700;
  text-transform: uppercase;
  text-align: center;
  
  &.disabled {
    opacity: .7;
    pointer-events: none;
  }
  
  &:focus,
  &:hover {
    outline: 2px solid rgba(35, 31, 32, .8); 
  }
`;

export default StyleInput;
