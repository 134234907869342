import PropTypes from 'prop-types';
import React, { useContext, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { ProgressBar } from 'react-step-progress-bar';
import { ThemeContext } from 'styled-components';
import Header from '../Header/Header';
import StyleLayout from './styled';
import { colors } from '../../utils/vars';

const Layout = ({ children }) => {
  const { status, step, questions } = useSelector((state) => state.quizzs);
  const themeContext = useContext(ThemeContext);
  return (
    <StyleLayout status={status}>
      {
            status === 'play' && (
            <div className="progress-bar-wrapper">
              <ProgressBar
                percent={((step + 1) * 100) / questions?.scenario?.length}
                filledBackground={`linear-gradient(to right, ${themeContext?.primary}, ${themeContext?.primary})`}
              />
            </div>
            )
        }

      {
            status !== 'finish' && (<Header />)
      }
      {children}
    </StyleLayout>
  );
};

Layout.propTypes = {
  children: PropTypes.node.isRequired,
};

export default Layout;
