import debug from 'debug';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import _ from 'lodash';
import { ThemeProvider } from 'styled-components';
import Finish from './components/Finish/Finish';
import Layout from './components/Layout/Layout';
import Load from './components/LoadingPage/Load';
import Login from './components/Login/Login';
import Background from './components/Background/Background';
import Question from './components/Question/Question';
import { resetQuestion } from './Store/question/slice';
import {
  addQuestions,
  addRoomId, finish,
  next,
  reset,
} from './Store/quizzs/slice';
import { resetUsers } from './Store/users/slice';
import axios from './utils/axios';
import GlobalStyle from './utils/globalStyle';
import ReloadOnBrowserOpen from './components/ReloadOnBrowserOpen/ReloadOnBrowserOpen';
import { colors } from './utils/vars';

const log = debug('quizz:app');

function App() {
  const {
    status, question, roomId, size,
  } = useSelector((state) => state.quizzs);
  const dispatch = useDispatch();
  const [groups, setGroups] = useState();
  const [logoUrl, setLogoUrl] = useState();
  const [sessionType, setSessionType] = useState();
  const [sessionIsFinished, setSessionIsFinished] = useState();
  const [theme, setTheme] = useState({
    primary: colors.blaze,
    secondary: colors.crusta,
  });
  const [primaryBg, setPrimaryBg] = useState();
  const [secondaryBg, setSecondaryBg] = useState();
  const [repeatPrimaryBg, setRepeatPrimaryBg] = useState();
  const [repeatSecondaryBg, setRepeatSecondaryBg] = useState();

  const getSession = async (sessionId) => {
    const res = await axios.get(`/session/${sessionId}`);
    setSessionType(res?.data?.type || 'live');
    setSessionIsFinished(res?.data?.finished);
    if (res?.data?.groups) {
      const tmpGroups = [];
      res?.data?.groups?.forEach((item) => {
        tmpGroups.push({ value: item?.toUpperCase(), label: item?.toUpperCase() });
      });
      setGroups(tmpGroups);
    }
    if (_.get(res, 'data.quizzId.client.logo', '')?.includes('https')) {
      setLogoUrl(res?.data?.quizzId?.client?.logo);
    }
    if (_.get(res, 'data.quizzId.primaryBackground', false)) {
      setPrimaryBg(_.get(res, 'data.quizzId.primaryBackground'));
    }
    if (_.get(res, 'data.quizzId.secondaryBackground', false)) {
      setSecondaryBg(_.get(res, 'data.quizzId.secondaryBackground'));
    }
    if (_.get(res, 'data.quizzId.repeatPrimaryBg', false)) {
      setRepeatPrimaryBg(_.get(res, 'data.quizzId.repeatPrimaryBg'));
    }
    if (_.get(res, 'data.quizzId.repeatSecondaryBg', false)) {
      setRepeatSecondaryBg(_.get(res, 'data.quizzId.repeatSecondaryBg'));
    }

    if (_.get(res, 'data.quizzId.primaryColor', false) || _.get(res, 'data.quizzId.secondaryColor', false)) {
      const tmpTheme = {};

      if (_.get(res, 'data.quizzId.primaryColor', false)) {
        tmpTheme.primary = res?.data?.quizzId?.primaryColor;
      } else {
        tmpTheme.primary = colors.blaze;
      }

      if (_.get(res, 'data.quizzId.secondaryColor', false)) {
        tmpTheme.secondary = res?.data?.quizzId?.secondaryColor;
      } else {
        tmpTheme.secondary = colors.crusta;
      }

      if (_.get(res, 'data.quizzId.labelColor', false)) {
        tmpTheme.labelColor = res?.data?.quizzId?.labelColor;
      } else {
        tmpTheme.labelColor = colors.blaze;
      }

      setTheme({ ...tmpTheme });
    }
    if (res?.data?.finished && res?.data?.type === 'free') {
      dispatch(finish());
    }
    if (status === 'finish' && !res?.data?.finished && res?.data?.type !== 'free') {
      dispatch(reset());
      dispatch(resetQuestion());
      dispatch(resetUsers());
    }
    dispatch(addRoomId(sessionId));
    dispatch(addQuestions(res?.data?.quizzId));
  };

  useEffect(() => {
    const sessionId = window.location.pathname.replace('/', '');
    if (roomId && sessionId !== roomId) {
      dispatch(reset());
      dispatch(resetQuestion());
      dispatch(resetUsers());
    } else {
      getSession(sessionId);
    }
  }, [roomId]);

  useEffect(() => {
    let timer;
    if (status === 'loading') {
      timer = setTimeout(() => {
        dispatch(next());
      }, 2000);
    }
    return () => {
      clearTimeout(timer);
    };
  }, [status]);

  return (
    <ThemeProvider theme={theme}>
      <Layout>
        {
          status === 'start' && groups && !sessionIsFinished && (<Background url={primaryBg} repeat={repeatPrimaryBg} />)
        }
        <GlobalStyle />
        <ReloadOnBrowserOpen />
        {status === 'loading' && <Load />}

        {status === 'start' && groups && !sessionIsFinished && (
          <Login
            logoUrl={logoUrl}
            groups={groups}
          />
        )}
        {['play', 'wait']?.includes(status) && question && sessionType && status !== 'finish' && !sessionIsFinished && <Question sessionType={sessionType} />}
        {['play', 'wait']?.includes(status) && question && sessionType && status !== 'finish' && !sessionIsFinished && secondaryBg && <Background url={secondaryBg} repeat={repeatSecondaryBg} />}

        {(status === 'finish' || sessionIsFinished) && sessionType && <Finish sessionType={sessionType} />}
      </Layout>
    </ThemeProvider>
  );
}

export default App;
