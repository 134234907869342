import React, { useContext, useEffect } from 'react';
import PropTypes from 'prop-types';
import { CircularProgressbar, buildStyles, CircularProgressbarWithChildren } from 'react-circular-progressbar';
import { ThemeContext } from 'styled-components';
import StyledCircularProgress from './styled';
import { colors } from '../../utils/vars';

const CircularProgress = ({
  percentage,
}) => {
  const themeContext = useContext(ThemeContext);

  const convertHex = (hexCode, opacity = 1) => {
    let hex = hexCode.replace('#', '');

    if (hex.length === 3) {
      hex = hex[0] + hex[0] + hex[1] + hex[1] + hex[2] + hex[2];
    }

    const r = parseInt(hex.substring(0, 2), 16);
    const g = parseInt(hex.substring(2, 4), 16);
    const b = parseInt(hex.substring(4, 6), 16);

    /* Backward compatibility for whole number based opacity values. */
    if (opacity > 1 && opacity <= 100) {
      // eslint-disable-next-line no-param-reassign
      opacity /= 100;
    }

    return `rgba(${r},${g},${b},${opacity})`;
  };

  return (
    <StyledCircularProgress>
      {
            themeContext && (
            <CircularProgressbarWithChildren
              value={percentage}
              text={`${percentage} / 100`}
              styles={buildStyles({
                pathColor: convertHex(themeContext.primary, (percentage / 100)),
                textColor: themeContext?.primary,
                trailColor: '#FFFFFF',
                textSize: '13px',
                backgroundColor: '#FFFFFF',
              })}
            >
              <div style={{
                fontSize: 12, marginTop: 45, paddingTop: 5, borderTop: `2px solid ${colors.athens}`,
              }}
              >
                <strong>Points</strong>
              </div>
            </CircularProgressbarWithChildren>
            )
        }

    </StyledCircularProgress>
  );
};

CircularProgress.propTypes = {
  percentage: PropTypes.string.isRequired,
};

export default CircularProgress;
