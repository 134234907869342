import styled from 'styled-components';

const StyleLayout = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  height:  ${(props) => (props?.status !== 'finish' ? 'unset' : '100px')};
  justify-content: center;
  position: relative;
  
  &.loading {
    padding: 0 20px;
  }
  .progress-bar-wrapper {
      position: absolute;
      top: 0;
      width: 100%;
    .RSPBprogression {
      border-radius: unset;
    }
  }  

  .wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    width: 100%;
    border: solid blue;
    max-width: 600px;
    height: 100%;
    overflow-y: auto;
    margin: 0 auto;
    padding-top: ${(props) => (props?.status === 'start' || props?.status === 'finish' ? '0' : '100px')};
  }
`;

export default StyleLayout;
