import PropTypes from 'prop-types';
import React from 'react';
import { ReactComponent as Check } from '../../svgs/check.svg';
import StyleCheck from './styled';

const Input = ({
  id, label, type, register, disabled, defaultChecked,
}) => (
  <StyleCheck type={type}>
    <input
      id={id}
      type={type}
      {...register}
      value={id}
      disabled={disabled}
      defaultChecked={defaultChecked}
    />
    <label htmlFor={id} className={disabled && 'disabled'}>
      {label}
      <Check />
    </label>
  </StyleCheck>
);

Input.propTypes = {
  id: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  type: PropTypes.oneOf(['radio', 'checkbox']).isRequired,
  register: PropTypes.func.isRequired,
  disabled: PropTypes.bool.isRequired,
  defaultChecked: PropTypes.bool.isRequired,
};

export default Input;
