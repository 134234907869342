import styled from 'styled-components';
import { colors } from '../../utils/vars';
import bg from '../../images/bg.png';
import { breakpoint } from '../../utils/mixins';

const StyleLoad = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
  width: 100%;
  background-color: ${colors.whisper};
  background-image: url(${bg});
  background-repeat: no-repeat;
  background-position: bottom center;
  
  h1 {
    transform: translateX(-50px);
    opacity: 0;
    animation: starter .8s forwards;
    color: ${colors.thunder};
    font-family: 'Canaro', sans-serif;
    font-size: 7.4rem;
    font-weight: 900;
    text-transform: uppercase;
    ${breakpoint.s`
         font-size: 3rem;
    `}
  }
  
  p {
    transform: translateX(20px);
    opacity: 0;
    animation: starter .5s .8s forwards;
    color: ${colors.blaze}; 
    font-family: 'Merriweather', sans-serif;
    font-size: 2.4rem;
    font-weight: 700;
    font-style: italic;
    text-align: right;
    ${breakpoint.s`
         font-size: 1.8rem;
    `}
  }
  
  @keyframes starter {
    to {
      transform: translate(0);
      opacity: 1;
    }
  }
`;
export default StyleLoad;
