import styled from 'styled-components';
import { colors } from '../../utils/vars';
import { breakpoint } from '../../utils/mixins';

export const StyleFinish = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  justify-content: flex-start;
  align-items: center;
  color: ${colors.scooter};
  font-size: 1.8rem;
  font-weight: 600;
  text-align: center;

  .header {
    position: relative;
    height: 300px;
    width: 100%;
    background: ${(props) => (props.theme.primary)};
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 30px;
    border-bottom-left-radius: 50px;
    border-bottom-right-radius: 50px;
    box-shadow: ${colors.emperor} 0 0 5px 2px;
    margin-bottom: 50px;
    overflow: hidden;

    ${breakpoint.xxs`
       height: 250px;
    `}
    
    
    h2 {
      color: ${colors.white};
      font-family: 'Canaro', sans-serif;
      font-size: 2rem;
      font-weight: 700;
      margin: 0;
      padding: 0;
      text-transform: unset;

      ${breakpoint.xxs`
        font-size: 1.6rem;
    `}
    }
  }

  .body {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 50px;
    padding: 0 20px;

    &_titles {
      span {
        strong {
          color: ${(props) => props.theme.secondary};
        }
      }


      &.winner {
        span {
          strong {
            color: ${(props) => props.theme.primary};
          }
        }
      }
    }

    &_details {
      width: 100%;
      display: flex;
      flex-direction: column;
      gap: 20px;
      overflow-y: auto;
      max-height: 400px;

      .item {
        width: 100%;
        display: flex;
        justify-content: space-between;

        &.winner {
          color: ${(props) => props.theme.secondary};
        }

        &.first {
          color: ${(props) => props.theme.primary};
        }
      }
    }
  }

  .podium {
    display: flex;
    height: 250px;
    gap: 20px;
    width: 100%;
    justify-content: center;

    ${breakpoint.s`
        height: 180px;
        gap: 10px;
  `}
    .winner {
      width: 30%;
      display: flex;
      flex-direction: column;
      justify-content: flex-end;
      gap: 20px;

      &.first, &.second, &.third {
        .chair {
          background-color: ${(props) => props.theme.primary};
          display: flex;
          justify-content: center;
          align-items: center;
          padding-bottom: 5px;

          span {
            font-size: 6rem;
            color: ${colors.white};
            ${breakpoint.s`
              font-size: 3rem !important;
            `}
          }
        }
      }

      &.first {
        p {
          animation: bounce 2s infinite;

          @keyframes bounce {
            0%, 20%, 50%, 80%, 100% {
              transform: translateY(0);
            }
            40% {
              transform: translateY(-30px);
            }
            60% {
              transform: translateY(-15px);
            }
          }
        }

        .chair {
          background-color: ${(props) => props.theme.secondary};
          height: 200px;

          ${breakpoint.s`
               height: 150px;
            `}
          span {
            font-size: 10rem;
            ${breakpoint.s`
              font-size: 5rem !important;
            `}
          }
        }
      }

      &.second {
        .chair {
          height: 150px;
          ${breakpoint.s`
               height: 100px;
            `}
        }
      }

      &.third {
        .chair {
          height: 100px;
          ${breakpoint.s`
               height: 50px;
            `}
        }
      }


    }
  }

  .thanks-free-wrapper {
    display: flex;
    height: 500px;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    margin: auto 0;
  }
`;

export const StylePersonalClassment = styled.section`
  margin-top: 30px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 20px;
  width: 100%;
  padding: 0 10px;
  
  .meta {
    font-style: italic;
    display: flex;
    flex-direction: column;
    gap: 10px;
    text-align: left;
    p {
      strong {
        color: ${(props) => props.theme.primary};
      }
    }
    
    &.winner {
      p {
        strong {
          color: ${(props) => props.theme.secondary};
        }
      }
    }
  }
`;

export const StyledFinishTitle = styled.section`
  width: 100%;
  padding: 0 10px;
  text-align: left;
  

`;
export const StyleGlobalClassment = styled.section`
  padding: 0 10px;
  width: 100%;
  max-width: 100%;
  overflow-y: auto;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 10px;
  min-height: 200px;
 .item {
   width: 50%;
   display: flex;
   justify-content: space-between;
   ${breakpoint.xs`
              width: 100%;
  `}
   &.winner {
     color: ${(props) => props.theme.primary};
   }
   
   &.first {
     color: ${(props) => props.theme.secondary};
   }
 }
  

`;

export const StyleLineOnBoard = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  div {
    position: relative;
    display: flex;
    align-items: center;
    height: 40px;
    
    .personal-onboard {
      color: ${colors.white};

      &::before {
        background-color: ${(props) => props.theme.secondary};
      }
    }
  }

`;

export const StylePosition = styled.span`
  margin-right: 10px;
  width: 40px;
  font-size: 1.6rem;
  color: ${colors.manatee};


  &::before {
    content: "";
    position: absolute;
    width: 40px;
    height: 40px;
    left: 0;
    top: 0;
    z-index: -1;
    border-radius: 50%;
    background-color: ${colors.manatee30};
  }
`;
export const StyleName = styled.p`
  font-size: 1.4rem;
  text-transform: uppercase;
  display: flex;
  justify-content: center;
  align-items: center;

  svg {
    width: 15px;
    height: 15px;
    margin-left: 5px;
    fill: ${(props) => props.theme.secondary};
  }
`;
export const StyleScore = styled.p`
  font-size: 1.4rem;
  font-weight: 700;
  color: ${colors.emperor};
`;

export const WrapperOnBoard = styled.div`
  max-height: 400px;
  overflow-y: auto;
`;
