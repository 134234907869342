import React from "react";
import { Provider } from "react-redux";
import { PersistGate } from "redux-persist/integration/react";
import { createRoot } from "react-dom/client";
import App from "./App";
import { SocketProvider } from "./contexts/Socket";
import UserContext from "./contexts/User";
import { persistor, store } from "./Store";
import "./utils/normalize.css";
import "plyr-react/plyr.css";
import "react-circular-progressbar/dist/styles.css";
import "react-step-progress-bar/styles.css";

const container = document.getElementById("root");
const root = createRoot(container);
root.render(
  <React.StrictMode>
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <SocketProvider>
          <UserContext>
            <App />
          </UserContext>
        </SocketProvider>
      </PersistGate>
    </Provider>
  </React.StrictMode>
);
