import styled from 'styled-components';
import { colors } from '../../utils/vars';

const StyledDotCollision = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
  height: auto;
  margin-top: 8px;

  .dot-collision {
    position: relative;
    width: 10px;
    height: 10px;
    border-radius: 5px;
    background-color: ${(props) => props.theme.primary};
    color: ${(props) => props.theme.primary};
  }

  .dot-collision::before, .dot-collision::after {
    content: '';
    display: inline-block;
    position: absolute;
    top: 0;
  }

  .dot-collision::before {
    left: -10px;
    width: 10px;
    height: 10px;
    border-radius: 5px;
    background-color: ${(props) => props.theme.primary};
    color: ${(props) => props.theme.primary};
    animation: dotCollisionBefore 2s infinite ease-in;
  }

  .dot-collision::after {
    left: 10px;
    width: 10px;
    height: 10px;
    border-radius: 5px;
    background-color: ${(props) => props.theme.primary};
    color: ${(props) => props.theme.primary};

    animation: dotCollisionAfter 2s infinite ease-in;
    animation-delay: 1s;
  }

  @keyframes dotCollisionBefore {
    0%,
    50%,
    75%,
    100% {
      transform: translateX(0);
    }
    25% {
      transform: translateX(-15px);
    }
  }

  @keyframes dotCollisionAfter {
    0%,
    50%,
    75%,
    100% {
      transform: translateX(0);
    }
    25% {
      transform: translateX(15px);
    }
  }
`;

export default StyledDotCollision;
