/* eslint-disable react/jsx-props-no-spreading */
import React, { useContext, useEffect } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import Select from 'react-select';
import PropTypes from 'prop-types';
import { SocketContext } from '../../contexts/Socket';
import { next } from '../../Store/quizzs/slice';
import { addUser } from '../../Store/users/slice';
import axios from '../../utils/axios';
import Button from '../Button/Button';
import StyleLogin from './styled';
import Question from '../Question/Question';

// eslint-disable-next-line react/prop-types
function Login({ groups, logoUrl }) {
  const dispatch = useDispatch();
  const sessionId = useSelector((state) => state.quizzs.roomId);
  const socket = useContext(SocketContext);
  const {
    handleSubmit, register, formState: { errors }, control,
  } = useForm();
  const handleFormUser = async (values) => {
    const user = await axios.post('/participant', {
      sessionId,
      group: groups?.length === 1 ? groups[0].value : values.group?.value,
      firstName: values.firstName,
      lastName: values.lastName,
    });
    try {
      socket.emit('createUser', {
        sessionId,
        group: groups?.length === 1 ? groups[0].value : values.group?.value,
        firstName: values.firstName,
        lastName: values.lastName,

      });
      dispatch(addUser({
        group: groups?.length === 1 ? groups[0].value : values.group?.value,
        firstName: values.firstName,
        lastName: values.lastName,
        userId: user?.data?._id,
      }));
      document.body.style.zoom = '100%';
      window.scrollTo({ top: 0, behavior: 'smooth' });
      dispatch(next());
    } catch (err) {
      console.error('Une erreur est survenue');
    }
  };

  useEffect(() => console.log('errors ', errors), [errors]);

  return (
    <StyleLogin>
      {
        logoUrl ? (
          <div className="logo-wrapper">
            <figure>
              <img src={logoUrl} alt="logo" />
            </figure>
          </div>
        ) : (<h1>Connexion</h1>)
      }

      <form
        onSubmit={handleSubmit(handleFormUser)}
      >
        <div className="inputs-wrapper">
          <div className="login-field">
            <span className="login-label">Nom</span>

            <input
              type="text"
              {...register('lastName', {
                required: 'Ce champs est requis',
              })}
            />
            {errors?.lastName && <span className="message-error">{errors?.lastName?.message}</span>}

          </div>

          <div className="login-field">
            <span className="login-label">Prénom</span>

            <input
              type="text"
              {...register('firstName', {
                required: 'Ce champs est requis',
              })}
            />
            {errors?.firstName && <span className="message-error">{errors?.firstName?.message}</span>}
          </div>

          {
            groups?.length > 1 && (
            <div className="login-field">
              <span className="login-label">Groupe</span>

              <Controller
                control={control}
                name="group"
                rules={{ required: 'Ce champs est requis' }}
                render={({
                  field: {
                    onChange, ref,
                  },
                }) => (
                  <Select
                    onChange={onChange}
                    inputRef={ref}
                    options={groups}
                    className="login-select"
                    classNamePrefix="login-select"
                    isSearchable={false}
                    placeholder="Sélectionnez votre groupe"
                  />
                )}
              />
              {errors?.group && <span className="message-error">{errors?.group?.message}</span>}

            </div>
            )
          }
        </div>

        <Button
          type="submit"
          className=""
        >
          {(errors?.firstName && errors?.lastName && errors?.group) ? 'Remplissez les champs' : 'S\'enregistrer'}
        </Button>
      </form>
    </StyleLogin>
  );
}

Login.propTypes = {
  groups: PropTypes.arrayOf(PropTypes.string).isRequired,
  logoUrl: PropTypes.string.isRequired,
};
export default Login;
